<template>
  <div class="mt-4">
    <el-row :gutter="20">
      <el-col :span="12" class="mb-3">
        <interactions-filter />
      </el-col>
      <el-col :span="12" class="is-flex is-justify-content-flex-end mb-3">
        <el-button type="primary" icon="el-icon-plus" @click="showModal = true">
          Добавить взаимодействие
        </el-button>
      </el-col>
      <el-col :span="24" class="mb-6">
        <interactions-table />
      </el-col>
    </el-row>
    <interactions-modal :show.sync="showModal" />
  </div>
</template>

<script>
import InteractionsModal from './Interactions/Modal';
import InteractionsTable from './Interactions/Table';
import InteractionsFilter from './Interactions/Filter';

export default {
  name: 'Interactions',
  components: { InteractionsModal, InteractionsTable, InteractionsFilter },
  data() {
    return { showModal: false };
  },
  async mounted() {
    await this.$store.dispatch('FETCH_CLIENT', this.$route.params.id);
    await this.$store.dispatch('FETCH_INTERACTIONS');
  },
  destroyed() {
    this.$store.dispatch('RESET_INTERACTIONS');
  },
};
</script>

<style lang="scss" scoped>
.input-full {
  width: 100% !important;
}
</style>
