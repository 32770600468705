<template>
  <div class="interactions-table">
    <general-table
      :data="interactions ? interactions : []"
      :loading="loading"
      :fields="fields"
      :rules="rules"
      @edit="handleEditInteractions"
      @delete="handleDeleteInteractions"
    />
    <interactions-modal :show.sync="showModal" :interaction.sync="interaction" />
  </div>
</template>
<script>
import GeneralTable from '@/components/common/GeneralTable.vue';
import { mapGetters } from 'vuex';
import InteractionsModal from './Modal.vue';

export default {
  name: 'InteractionsTable',
  components: { GeneralTable, InteractionsModal },
  data() {
    return {
      loading: false,
      fields: [
        { label: '#', value: 'id', width: 50 },
        { label: 'Событие', value: 'type.title' },
        { label: 'Мессенджер', value: 'messenger' },
        { label: 'Дата', value: 'date' },
        { label: 'Менеджер', value: 'manager.name' },
      ],
      rules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      interaction: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({ interactions: 'INTERACTIONS' }),
  },
  methods: {
    handleEditInteractions(interaction) {
      this.interaction = {
        ...interaction,
        type_id: interaction.type.id,
        manager_id: interaction.manager.id,
      };
      this.showModal = true;
    },
    handleDeleteInteractions(interaction) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          this.loading = true;
          try {
            await this.$store.dispatch('DELETE_INTERACTION', interaction.id);
            this.$notify({
              title: 'Успешно',
              message: 'Взаимодействие успешно удалено',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
          this.loading = false;
        })
        .catch(() => {});
    },
  },
};
</script>
