<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%">
    <el-form :model="data" label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Тип события">
            <el-select v-model="data.type_id" placeholder="Запрос информации">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Дата и время">
            <el-date-picker
              v-model="data.date"
              class="input-full"
              type="date"
              placeholder="Дата обращения"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Мессенджер">
            <el-select v-model="data.messenger" placeholder="Viber">
              <el-option label="WhatsApp" value="WhatsApp" />
              <el-option label="Instagram" value="Instagram" />
              <el-option label="Vk" value="Vk" />
              <el-option label="Telegram" value="Telegram" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="managers" :span="12">
          <el-form-item label="Менеджер">
            <el-select
              v-model="data.manager_id"
              style="width: 90%"
              placeholder="Выберите менеджера"
            >
              <el-option
                v-for="item in managers.data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Текст взаимодействие">
            <el-input v-model="data.text" type="textarea" :rows="4" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="primary" :loading="loading" @click="sendData"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { FETCH_EMPLOYEES } from '@/app/settings/employes/shared/state/types/action_types';

export default {
  name: 'InteractionsModal',
  props: {
    title: {
      type: String,
      default: 'Добавить взаимодействие',
    },
    show: {
      type: Boolean,
      default: false,
    },
    interaction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      defaultData: {
        type_id: null,
        manager_id: null,
      },
      options: [
        { value: 1, label: 'Запрос информации' },
        { value: 2, label: 'Изменение условий доставки' },
        { value: 3, label: 'Изменение рациона' },
        { value: 4, label: 'Продление заказа' },
        { value: 5, label: 'Заморозка' },
        { value: 6, label: 'Обратная связь' },
        { value: 7, label: 'Жалоба' },
      ],
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    data: {
      get() {
        return this.interaction ? this.interaction : this.defaultData;
      },
      set(value) {
        this.$emit('update:interaction', value);
      },
    },
    ...mapState({
      managers: (state) => state.employesState.employesData.data,
    }),
  },
  async mounted() {
    await this.fetchManagers();
  },
  methods: {
    ...mapActions({
      fetchManagers: FETCH_EMPLOYEES,
    }),
    async sendData() {
      if (this.data.id) {
        await this.$store.dispatch('UPDATE_INTERACTION', this.data);
        this.visible = false;
        return true;
      }
      await this.$store.dispatch('STORE_INTERACTION', this.data);
      setTimeout(async () => {
        await this.$store.dispatch('FETCH_INTERACTIONS');
      }, 1000);
      this.visible = false;
    },
  },
};
</script>
