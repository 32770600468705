<template>
  <div class="interactions-filter">
    <!-- <el-date-picker v-model="value1" class="mr-3" type="date" placeholder="Дата обращения">
    </el-date-picker>
    <el-autocomplete
      v-model="state1"
      :fetch-suggestions="querySearch"
      placeholder="Тип события"
      @select="handleSelect"
    ></el-autocomplete> -->
  </div>
</template>
<script>
export default {
  name: 'InteractionsFilter',
};
</script>
